import React from "react";
import styles from "../../styles/components/layout/HsFooter.module.scss";
import { Link } from 'react-router-dom';
import footerLogo from '../../assets/images/footer_logo_green.svg';

function HsFooter() {
    return (
        <>
            <footer className={styles['hs-footer']}>
                <div className={styles['hs-footer-inner']}>
                    <div className={styles['hs-footer-company-info-area']}>
                        <div className={styles['hs-footer-company-logo-wrap']}>
                            <img src={footerLogo} alt="휴머스온" className={styles['hs-footer-company-logo']} />
                            <strong className={styles['hs-footer-company-logo-title']}>(주)휴머스온</strong>
                        </div>
                        <dl className={styles['hs-footer-company-info-list']}>
                            <div className={styles['hs-footer-company-info-item']}>
                                <dt className={styles['hs-footer-company-info-title']}>대표 : </dt>
                                <dd className={styles['hs-footer-company-info-contents']}>백동훈</dd>
                            </div>
                            <div className={`${styles['hs-footer-company-info-item']} ${styles['order']}`}>
                                <dt className="blind">주소 : </dt>
                                <dd className={styles['hs-footer-company-info-contents']}>서울시 강남구 선릉로 433 세방빌딩 10F, 12F, 18F</dd>
                            </div>
                            <div className={`${styles['hs-footer-company-info-item']} ${styles['right']}`}>
                                <dt className={styles['hs-footer-company-info-title']}>사업자등록번호 : </dt>
                                <dd className={styles['hs-footer-company-info-contents']}>147-87-02929</dd>
                            </div>
                            <div className={`${styles['hs-footer-company-info-item']} ${styles['right']} ${styles['order']}`}>
                                <dt className={styles['hs-footer-company-info-title']}>팩스 : </dt>
                                <dd className={styles['hs-footer-company-info-contents']}>02-6008-9270</dd>
                            </div>
                        </dl>
                        <ul className={styles['hs-footer-terms-list']}>
                            <li className={styles['hs-footer-terms-item']}>
                                <Link to="/company" className={styles['hs-footer-terms-link']}>회사소개</Link>
                            </li>
                            <li className={styles['hs-footer-terms-item']}>
                                <Link to="/terms/service" className={styles['hs-footer-terms-link']}>이용약관</Link>
                            </li>
                            <li className={styles['hs-footer-terms-item']}>
                                <strong>
                                    <Link to="/terms/privacy" className={styles['hs-footer-terms-link']}>개인정보 처리방침</Link>
                                </strong>
                            </li>
                        </ul>

                        <ul className={styles['hs-footer-sns-list']}>
                            <li className={styles['hs-footer-sns-item']}>
                                <Link to="https://www.facebook.com/humuson.official" target="_blank" className={`${styles['hs-footer-sns-link']} ${styles['facebook']}`}>
                                    <span className="blind">페이스북 바로가기</span>
                                </Link>
                            </li>
                            <li className={styles['hs-footer-sns-item']}>
                                <Link to="https://blog.naver.com/tason_official" target="_blank" className={`${styles['hs-footer-sns-link']} ${styles['blog']}`}>
                                    <span className="blind">블로그 바로가기</span>
                                </Link>
                            </li>
                            <li className={styles['hs-footer-sns-item']}>
                                <Link to="http://blog.tason.com/" target="_blank" className={`${styles['hs-footer-sns-link']} ${styles['wordpress']}`}>
                                    <span className="blind">워드프레스 블로그 바로가기</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <p className={styles['hs-footer-copyright']}>&copy; Copyright humusOn Inc. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}

export default HsFooter;