import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styles from '../../styles/components/layout/HsHeader.module.scss';
import headerLogo from '../../assets/images/footer_logo_green.svg';
import MENU_ITEMS from '../../assets/data/HsHeaderItems'

function HsHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeSubMenus, setActiveSubMenus] = useState([false, false]);
    const menuButtonRefs = useRef([]);
    const subListRefs = useRef([]);
    const handleMenuButtonClick = () => {
        if (window.innerWidth <= 992) {
            setIsMenuOpen(!isMenuOpen);
        }
        setActiveSubMenus([false, false]);
    };

    const handleSubMenuButtonClick = (index) => {

        activeSubMenus[index] = !activeSubMenus[index];
        setActiveSubMenus([...activeSubMenus])
        /*console.log(activeSubMenus)
        setActiveSubMenus((prevActiveSubMenus) =>
            prevActiveSubMenus.map((isActive, i) => (i === index ? !isActive : false))
        );*/
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            const { target } = event;
            const { current } = subListRefs;
            const currLen = current.length;
            for (let i = 0; i < currLen; i++) {
                if (current[i] && !current[i].contains(target) &&
                    !menuButtonRefs.current[i].contains(target)
                ) {
                    setActiveSubMenus((prevActiveSubMenus) =>
                        prevActiveSubMenus.map((isActive, index) => (index === i ? false : isActive))
                    );
                }
            }
        };
        const handleDocumentKeyDown = (event) => {
            if (event.code.toLowerCase() === 'tab') {
                handleDocumentClick(event);
            }
        }
        document.addEventListener("click", handleDocumentClick);
        document.addEventListener("keyup", handleDocumentKeyDown);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
            document.removeEventListener("keyup", handleDocumentKeyDown);
        };
    }, []);


    const handleLogoClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <header className={styles['hs-header']}>
                <div className={styles['hs-header-inner']}>
                    <div className={styles['hs-header-logo-container']}>
                        <Link to="/" className={styles['hs-header-logo-link']} onClick={handleLogoClick}>
                            <img src={headerLogo} alt="휴머스온" className={styles['hs-header-logo-image']} />
                        </Link>
                    </div>
                    <nav className={styles['hs-header-menu']} role="navigation">
                        <button type="button" className={styles['hs-header-menu-toggle-button']} onClick={handleMenuButtonClick}>
                            <span className="blind">메뉴 열기</span>
                        </button>
                        <ul className={`${styles['hs-header-menu-list']} ${isMenuOpen ? styles.active : ''}`}>

                            {MENU_ITEMS.map((menuItem, index) => (
                                <li key={index} className={styles['hs-header-menu-item']}>
                                    {menuItem.subMenuItems ? (
                                        <>
                                            <button
                                                type="button"
                                                className={styles['hs-header-menu-button']}
                                                onClick={() => handleSubMenuButtonClick(index)}
                                                ref={(el) => (menuButtonRefs.current[index] = el)}
                                            >
                                                {menuItem.label}
                                            </button>
                                            <ul onMouseLeave={() => setActiveSubMenus([false, false])}
                                                className={`${styles['hs-header-menu-sub-list']} ${activeSubMenus[index] ? styles.active : ''}`}
                                                role="menu"
                                                ref={(el) => (subListRefs.current[index] = el)}
                                            >
                                                {menuItem.subMenuItems.map((subMenuItem, subIndex) => (
                                                    <li key={subIndex} className={styles['hs-header-menu-sub-item']}>
                                                        {subMenuItem.target ? (
                                                            <a href={subMenuItem.url} target={subMenuItem.target} rel="noopener noreferrer" className={styles['hs-header-menu-sub-link']}>
                                                                {subMenuItem.label}
                                                            </a>
                                                        ) : (
                                                            <HashLink to={subMenuItem.url} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} className={styles['hs-header-menu-sub-link']}>
                                                                {subMenuItem.label}
                                                            </HashLink>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : (
                                        <Link to={menuItem.url} className={styles['hs-header-menu-link']}>
                                            {menuItem.label}
                                        </Link>
                                    )}
                                </li>
                            ))}
                            <li className={styles['hs-header-menu-item']}>
                                <Link to="https://humuson.career.greetinghr.com/" target="_blank" className={styles['hs-header-menu-link']}>채용</Link>
                            </li>
                            <li className={styles['hs-header-menu-button-item']}>
                                <Link to="https://www.tason.com/to/ma" target="_blank" className={styles['hs-header-menu-button-link']}>마케팅자동화 경험</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
}
export default HsHeader;